// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lt-yantr .monthly-payments-table {
  margin: 0;
}
#lt-yantr .monthly-payments-table .trigger {
  color: #02adf2;
  font-size: 27px;
  font-weight: 300;
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.4s;
}
#lt-yantr .monthly-payments-table .trigger:before {
  font-family: "lt5";
  content: "\\e97d";
  vertical-align: top;
}
#lt-yantr .monthly-payments-table .trigger.active:before {
  font-family: "lt5";
  content: "\\e97e";
  vertical-align: top;
}
#lt-yantr .monthly-payments-table .trigger.active .table-responsive {
  visibility: visible;
  opacity: 1;
  max-height: 100%;
}
@media (max-width: 480px) {
#lt-yantr .monthly-payments-table .trigger {
    font-size: 17px;
    padding: 10px 0;
}
}
#lt-yantr .monthly-payments-table ul {
  margin: 0;
  padding: 0;
}
#lt-yantr .monthly-payments-table ul li {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  border-top: solid #eee 1px;
}
#lt-yantr .monthly-payments-table ul li .trigger {
  font-weight: 300;
  padding: 10px 0 13px;
  cursor: pointer;
  transition: all 0.4s;
}
#lt-yantr .monthly-payments-table ul li .table-responsive {
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  max-height: 0;
  transition: all 0.5s;
}
@media (max-width: 767px) {
#lt-yantr .monthly-payments-table ul li .table-responsive {
    border: none;
    margin-bottom: 0;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
