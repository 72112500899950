// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lt-yantr.sidebar-template .row {
  display: flex;
  flex-direction: column;
}
#lt-yantr.sidebar-template .row .filter-sec {
  width: 100%;
  max-width: 300px;
}
@media (max-width: 992px) {
#lt-yantr.sidebar-template .row .filter-sec {
    max-width: 100%;
}
}
#lt-yantr.sidebar-template .row .filter-sec .calc-filter {
  padding: 15px 15px 25px;
}
#lt-yantr.sidebar-template .row .result-sec {
  width: 100%;
  max-width: 300px;
}
@media (max-width: 992px) {
#lt-yantr.sidebar-template .row .result-sec {
    max-width: 100%;
}
}
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section {
  padding: 25px 0 30px;
}
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section h3 {
  font-size: 18px;
}
@media (max-width: 992px) {
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section h3 {
    font-size: 24px;
}
}
@media (max-width: 767px) {
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section h3 {
    font-size: 22px;
}
}
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section .calculate-amount {
  font-size: 40px;
}
@media (max-width: 992px) {
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section .calculate-amount {
    font-size: 55px;
}
}
@media (max-width: 767px) {
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section .calculate-amount {
    font-size: 45px;
}
}
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section .next-step {
  font-size: 17px;
  margin: 20px 0 15px;
}
#lt-yantr.sidebar-template .row .result-sec .loan-payment-section .btn {
  width: 100%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
