// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a11y-modal .a11y-modal-dialog {
  max-width: 900px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  border-radius: 5px;
}
.a11y-modal .a11y-modal-dialog .a11y-modal-header h2 {
  color: #101f30;
  font-size: 27px;
  font-weight: 400;
  display: inline-block;
  border: none;
}
.a11y-modal .a11y-modal-dialog .disclosures-section {
  color: #454545;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .details-title {
  display: block;
  margin-bottom: 15px;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .details-title p {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 20px;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .disclosure-number {
  color: #101f30;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .divider {
  margin: 20px 0;
  display: block;
  border-bottom: 1px solid #ddd;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .divider:last-child {
  display: none;
}
.a11y-modal .a11y-modal-dialog .disclosures-section .disclosure-post, .a11y-modal .a11y-modal-dialog .disclosures-section .disclosure-post p {
  font-size: 16px;
  line-height: 1.8em;
}
.a11y-modal .a11y-modal-dialog .disclosures-section ul {
  padding-left: 30px;
}
.a11y-modal .a11y-modal-dialog .disclosures-section ul li {
  color: #454545;
  line-height: 1.6em;
  padding-left: 32px;
  margin-top: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
