export default {
    convertLoanTermInMonths(LoanTerm) {
        return LoanTerm * 12;
    },
    convertInterestRatePerMonths(InterestRate) {
        return InterestRate / 1200;
    },
    /*
        amount : total amount balance
        term : months (#yr*12)
        apr : rate/month (rate/12)
    */
    getPayment(amount, term, apr) {
        /* Calculates the monthly payment from annual percentage
        rate, term of loan in months and loan amount. * */
        let acc = 0;
        const base = 1 + apr;
        for (let i = 1; i <= term; i++) {
            acc += base ** -i;
        }
        return amount / acc;
    },
    monthlyPaymentUsingLoanTermInYears(LoanAmount, LoanTerm, InterestRate) {
        return this.getPayment(LoanAmount, this.convertLoanTermInMonths(LoanTerm), this.convertInterestRatePerMonths(InterestRate));
    },
    getMonthsLeft(CurrentInterestRate, CurrentMortgageBalance, CurrentMonthlyPayment) {
        const interestrate = CurrentInterestRate / 1200;
        const monthsleftcalcnumo = -Math.log(1 - interestrate * (CurrentMortgageBalance / CurrentMonthlyPayment));
        const monthsleftcalcdeno = Math.log(1 + interestrate);
        const MonthsLeftOnCurrentLoan = Math.round(monthsleftcalcnumo / monthsleftcalcdeno);
        return isNaN(MonthsLeftOnCurrentLoan) ? 0 : MonthsLeftOnCurrentLoan;
    },
    monthlyPaymentForRefinance(MonthsLeftOnCurrentLoan, CurrentMortgageBalance, CurrentMonthlyPayment, LoanTermInYears, NewInterestRate) {
        return this.monthlyPaymentUsingLoanTermInYears(CurrentMortgageBalance, LoanTermInYears, NewInterestRate);
    },
    getIntrestRate(termmonth, monthlypayment, currentbalance) {
        // Formula:- initialGuess = (2 (N * P - C)) / (N * C)
        const initialguess = 2 * (termmonth * monthlypayment - currentbalance) / (termmonth * currentbalance);
        const interestRate = this.getFinalIntrestRate(initialguess, termmonth, monthlypayment, currentbalance);
        return interestRate * 100;
    },
    getFinalIntrestRate(interestRateValue, termmonth, monthlypayment, currentbalance) {
        let newinterestrate = 0;
        let guess = 0;
        let interestRate = interestRateValue;

        while (Math.round(interestRate * 100, 5) !== Math.round(newinterestrate, 5) && guess < 20) {
            guess++;
            newinterestrate = interestRate;
            // Formula:-  CurrentGuess = Guess - ( (P - ( P * ( (1 + Guess )^-N ) ) - (Guess * C) ) / ( ( N * P * ( (1 + Guess) ^ (-N -1) ) ) - C )

            interestRate = newinterestrate - (monthlypayment - monthlypayment * (1 + newinterestrate) ** -termmonth - newinterestrate * currentbalance) / (termmonth * monthlypayment * (1 + newinterestrate) ** (-termmonth - 1) - currentbalance);
        }
        return interestRate;
    },
    minimumPayment(LoanAmount, InterestRate) {
        return this.convertInterestRatePerMonths(InterestRate) * LoanAmount;
    },
    getHomeEquityLoan(currentAppraisedHomeValue, amountOwe) {
        const ltvRatio = 0.85;
        return Math.round(ltvRatio * currentAppraisedHomeValue - amountOwe);
    },
    compute_PMT(principle, rate, months) {
        return principle * rate / (1 - (1 + rate) ** (-1 * months)) * 100 / 100;
    },
    getMonthlyPrincipalAndIntreset(mortgageAmount, amortizationPeriodInMonths, monthlyInterestRatePerc) {
        return mortgageAmount * (monthlyInterestRatePerc * (1 + monthlyInterestRatePerc) ** amortizationPeriodInMonths / ((1 + monthlyInterestRatePerc) ** amortizationPeriodInMonths - 1));
    },
    /* Note: This function is specifically for non-monthly payment amounts
        to get the total number of payments in the amortization.

        amount: loan amount
        perYear: number of payments in a year (ex. 26 for biweekly, 52 for weekly)
        interestRate: the loan rate / 12 (ex. 6% / 12 = 0.005)
        payment: the raw payment amount for the period. note: for biweekly this will = (monthly payment / 13) / 26.
                  for weekly this will = (monthly payment / 14) / 52.
    */
    getTotalPayments(amount, perYear, interestRate, payment) {
        let balance = amount;
        let periodInterest = 0;
        let periodPrincipal = 0;
        let i = 1;
        let _payment = payment;

        while (balance > 0) {
            periodInterest = interestRate * balance / (perYear / 12);
            if (balance - _payment <= 0) {
                _payment = balance;
                balance = 0;
            }

            periodPrincipal = _payment - periodInterest;

            if (balance !== 0) {
                balance -= periodPrincipal;
            }
            i++;
        }

        return i;
    },
};
