// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-wrapper[data-v-472d7e90] {
  text-align: center;
  clear: both;
}
.logo-wrapper .lt-logo[data-v-472d7e90] {
  max-width: 110px;
  margin: 15px auto 0;
  display: inline-block;
}
.logo-wrapper small[data-v-472d7e90] {
  font-size: 10px;
  color: #101f30;
  display: block;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
