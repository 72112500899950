// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/leaf-loader.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
body.a11y-modal-open {
  overflow: hidden;
}
body.a11y-modal-open .a11y-modal {
  position: fixed;
  top: 6%;
  right: 0;
  bottom: 5%;
  left: 0;
  margin: 0 auto;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog {
  opacity: 1;
}
@media (min-width: 768px) {
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-dialog {
    width: 700px;
    margin: auto;
}
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content {
  background-color: #fff;
  padding: 20px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .ltFormBusySpinner .spin .inner {
  height: 200px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100px 100px;
  background-position: center;
  background-repeat: no-repeat;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-header {
  position: relative;
  border-bottom: solid 1px #e1e1e1;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-header h2 {
  margin-top: 0;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body {
  padding: 15px 0;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .detail {
  color: #101f30;
  font-size: 15px;
  text-transform: none;
  line-height: 1.7em;
  padding-bottom: 15px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .detail h2 {
  font-size: 20px;
  font-family: "Lato", Arial, sans-serif;
  margin: 20px 0 10px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .detail p {
  color: #454545;
  font-size: 17px;
  line-height: 1.7em;
  margin: 0 0 1.5em;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .detail ul {
  line-height: 1.7em;
  margin-left: 20px;
  margin-bottom: 0;
  padding-top: 0 !important;
  padding-left: 0;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .detail ul li {
  font-size: 16px;
  list-style: disc;
  margin: 0;
  padding: 0 0 0.5em;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .content-navigation-ahead {
  text-align: right;
  border-top: 1px solid #e1e1e1;
  padding-top: 5px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .content-navigation-ahead a {
  font-size: 15px;
  color: #00aeef;
  text-decoration: none;
  transition: all 0.3s ease;
}
body.a11y-modal-open .a11y-modal .a11y-modal-dialog .a11y-modal-content .a11y-modal-body .content-navigation-ahead a:after {
  content: "››";
  font-size: 27px;
  font-weight: 300;
  color: #00aeef;
  position: relative;
  top: 2px;
  margin-left: 10px;
  transition: all 0.3s ease;
}
body.a11y-modal-open .a11y-modal .a11y-modal-video {
  padding-top: 30px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-video .a11y-modal-content {
  padding: 0;
}
body.a11y-modal-open .a11y-modal .a11y-modal-video .a11y-modal-content .a11y-modal-header h2 {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  transition: none;
}
body.a11y-modal-open .a11y-modal .a11y-modal-video .a11y-modal-content .a11y-modal-body {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
body.a11y-modal-open .a11y-modal .a11y-modal-video .a11y-modal-content .a11y-modal-body iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
body.a11y-modal-open .a11y-modal .disclaimer-modal-button {
  text-align: center;
}
body.a11y-modal-open .a11y-modal .a11y-modal-close-x {
  font-family: "Lato", Arial, sans-serif;
  font-size: 20px;
  background-color: #fff;
  color: #454545;
  line-height: 1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 0;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Removed focus Property*/
}
body.a11y-modal-open .a11y-modal .a11y-modal-close-x::before {
  content: "\\e982";
  font-family: "lt5" !important;
  font-size: 13px;
}
body.a11y-modal-open .a11y-modal .a11y-modal-close-x.a11y-modal-close:hover {
  opacity: 0.5;
}
body.a11y-modal-open .a11y-modal-overlay {
  display: block;
  opacity: 0.5;
}
.a11y-modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
}
@keyframes fadeindown {
0% {
    opacity: 0;
    transform: translateY(-5%);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
}
@keyframes fadeinhalf {
0% {
    opacity: 0;
}
100% {
    opacity: 0.5;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
