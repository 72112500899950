// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
#lt-yantr .calculate-amount-section[data-v-ff57912c] {
    padding: 25px 0 30px;
}
}
#lt-yantr .calculate-amount-section h3[data-v-ff57912c] {
  margin-bottom: 0.5em;
}
@media (max-width: 480px) {
#lt-yantr .calculate-amount-section h3[data-v-ff57912c] {
    font-weight: normal;
}
}
#lt-yantr .calculate-amount-section .calculate-amount[data-v-ff57912c] {
  font-size: 80px;
  font-weight: 300;
  color: #08c177;
  display: block;
  line-height: normal;
  white-space: nowrap;
  padding-bottom: 15px;
}
@media (max-width: 767px) {
#lt-yantr .calculate-amount-section .calculate-amount[data-v-ff57912c] {
    font-size: 55px;
    padding-bottom: 0;
}
}
@media (max-width: 480px) {
#lt-yantr .calculate-amount-section .calculate-amount[data-v-ff57912c] {
    font-size: 45px;
}
}
#lt-yantr .calculate-amount-section .next-step[data-v-ff57912c] {
  margin: 20px 0 15px;
}
#lt-yantr .calculate-amount-section.home-equity-section[data-v-ff57912c] {
  margin-top: 0;
}
@media (max-width: 992px) {
#lt-yantr .calculate-amount-section.home-equity-section .calculate-amount[data-v-ff57912c] {
    font-size: 50px;
}
}
@media (max-width: 480px) {
#lt-yantr .calculate-amount-section.home-equity-section .calculate-amount[data-v-ff57912c] {
    font-size: 35px;
}
}
#lt-yantr .calculate-amount-section.home-equity-section .maximum-amount[data-v-ff57912c] {
  font-size: 50px;
}
@media (max-width: 480px) {
#lt-yantr .calculate-amount-section.home-equity-section .maximum-amount[data-v-ff57912c] {
    font-size: 35px;
}
}
#lt-yantr .calculate-amount-section.loan-payment-section[data-v-ff57912c] {
  margin-top: 0;
}
#lt-yantr .disclosures[data-v-ff57912c] {
  color: #999;
  font-size: 12px;
  text-align: center;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
