// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calc-filter label[data-v-27b07f92] {
  color: #444;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  display: block;
}
@media (max-width: 380px) {
.calc-filter label[data-v-27b07f92] {
    font-size: 14px;
}
}
.gray-bg .calc-filter label[data-v-27b07f92] {
  font-family: "Source Sans Pro", sans-serif;
  color: #787878;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 15px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
